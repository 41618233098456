import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="3cfdc47bafde22e0ec45e86077899a36e2b73d13"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/la-diarrhee/"> En savoir plus sur la diarrhée </a>
              </li>
              <li className="active">
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils pour le traitement des symptomes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                  Causes de la diarrhée
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                  Stress et diarrhée
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/emploi-du-temps-charge/">
                      Emploi du temps chargé
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/stress-et-diarrhee/diarrhee-examens/">
                      Faire face aux examens
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                  Infection virale ou bactérienne
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                  La diarrhée pendant les règles
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/la-diarrhee-chronique/">
                  La diarrhée chronique
                </a>
              </li>
              <li>
                <a href="/fr/la-diarrhee/consultez-votre-medecin/">
                  Consulter un médecin?
                </a>
              </li>
              <li className="last">
                <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/">
                  Des habitudes alimentaires saines
                </a>
                <ul>
                  <li className="first">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/allergies-alimentaires/">
                      Allergies alimentaires{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/intoxication-alimentaire/">
                      Intoxication alimentaire
                    </a>
                  </li>
                  <li className="last">
                    <a href="/fr/la-diarrhee/des-habitudes-alimentaires-saines/recettes-sante/">
                      Recettes Santé
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Comment agit IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Comment agit IMODIUM®?"
                  />
                </div>
                <p>
                  Cette vidéo montre comment Imodium<sup>®</sup>&nbsp;aide à
                  restaurer l‘équilibre naturel de vos intestins.
                </p>
                <a href="/fr/comment-agit-imodium/">Plus d'info</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-14">
            <h1>Conseils pour le traitement des symptomes de la diarrhée</h1>
            <div className="img">
              <img
                src="/assets/files/pages/durchfallmythen.jpeg"
                width="701"
                height="289"
                alt="Conseils pour le traitement des symptomes de la diarrhée"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Conseils pour le traitement de la diarrhée</h2>
              </div>
              <div className="nine columns">
                <p>
                  Il subsiste encore beaucoup d’idées reçues et d’incertitudes
                  concernant le traitement des symptömes de la diarrhée. Voici
                  quelques conseils importants :
                </p>
              </div>
            </div>
            <h3>Conseil n°1 : Prévenez la déshydratation</h3>
            <p>
              La première étape importante en cas de diarrhée est de veiller à
              absorber suffisamment de liquide. Buvez de l’eau en quantité
              suffisante, de préférence 2 à 3 litres par jour. Il peut
              éventuellement s’avérer utile de prendre également des
              électrolytes en plus. Vous trouverez une composition prête à
              l’emploi en pharmacie.
            </p>
            <h3>Conseil n°2: En cas de diarrhée : ne pas attendre</h3>
            <p>
              Beaucoup de personnes croient que la diarrhée est utile pour
              évacuer de l’organisme les bactéries responsables de la diarrhée.
              Les agents pathogènes ne sont cependant pas éliminés par la
              diarrhée, mais par le système immunitaire de l’organisme. La
              diarrhée n’est qu’un symptôme. Bien souvent, la diarrhée n’est
              même pas provoquée par des bactéries, mais par{" "}
              <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                d’autres causes, non contagieuses
              </a>
              . Que des bactéries soient ou non impliquées,{" "}
			  <a href="/fr/medicaments-imodium/imodium-instant/">
                IMODIUM® Instant comprimés orodispersibles
              </a>
              &nbsp;ou{" "}
              <a href="/fr/medicaments-imodium/imodium-capsules/">
                IMODIUM® Capsules
              </a>
			  &nbsp;permet d’en
              atténuer rapidement les désagréments, tandis que votre système
              immunitaire continue à lutter contre l’infection. Vous vous sentez
              mieux, et vous pouvez poursuivre vos activités habituelles.
            </p>
            <h3>
              Conseil n°3 : Chez les enfants, un traitement rapide de la
              diarrhée est extrêmement important
            </h3>
            <p>
              La diarrhée prive le corps de liquides et nutriments précieux. Les
              enfants supportent moins bien que les adultes ce manque dans leur
              corps. C’est pourquoi il est important chez eux de renouveler
              rapidement ces liquides et d’enrayer si possible la diarrhée. Chez
              les enfants à partir de 6 ans, les symptômes de la diarrhée
              peuvent être traités avec{" "}
              <a href="/fr/medicaments-imodium/imodium-instant/">
                IMODIUM® Instant comprimés orodispersibles
              </a>
              &nbsp;ou{" "}
              <a href="/fr/medicaments-imodium/imodium-capsules/">
                IMODIUM® Capsules
              </a>
              . Votre pharmacie propose en outre des solutions d’électrolytes
              prêtes à l’emploi spécialement conçues pour les enfants, qui
              permettent de renouveler les minéraux perdus. Si le problème
              persiste, vous devez consulter votre médecin. Vous trouverez{" "}
              <a href="/fr/diarrhee-chez-les-enfants/">ici</a>&nbsp;d’autres
              informations sur la{" "}
              <a href="/fr/diarrhee-chez-les-enfants/">
                diarrhée chez les enfants
              </a>
              .
            </p>
            <h3>
              Conseil n°4: Veillez à ce que l'excès d'activité intestinale se réduise rapidement
            </h3>
            <p>
              L'utilisation d'IMODIUM® aidera à réduire l'activité intestinale afin que vos selles redeviennent plus fermes et réduisent la diarrhée. Songez-y : à cause de la
              diarrhée, votre intestin est souvent entièrement vide. Par
              conséquent, cela peut durer un ou deux jours avant qu’il ne soit
              de nouveau rempli. Ainsi, vous pouvez avoir l’impression d’être
              constipé, parce que vous n’avez pas de selles pendant cette
              période.
            </p>
            <h3>Conseil n°5 : La diarrhée peut être contagieuse</h3>
            <p>
              Étant donné que la diarrhée peut être provoquée par des agents
              pathogènes contagieux&nbsp;{" "}
              <a href="/fr/la-diarrhee/infection-virale-ou-bacterienne/">
                (bactéries ou virus, par exemple gastro-entérite)
              </a>
              , il est possible de contaminer quelqu’un. Les infections peuvent
              être transmises par le contact avec des personnes, surfaces ou
              objets contaminés, ou bien en mangeant ou buvant des aliments ou
              de l’eau contaminés.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
